import * as React from 'react'
import { Box, Text, Img } from '@chakra-ui/react'
import { Hero } from '../components/hero'
import { NavBar } from '../components/navbar'
import { SingleFeature } from '../components/singleFeature'
import { TripleFeature } from '../components/tripleFeature'
import { CtaSimple } from '../components/ctaSimple'
import fractalBg from '../assets/svg/info-illustrations/jagged-bg.png'
import * as Illustrations from '../assets/svg/InfoIllustrations'
import Layout from '../components/layout'
import Seo from '../components/seo'
import HexHoneycomb from '../assets/svg/flair/hex-honeycomb.svg'
import HexStrokeOpacity from '../assets/svg/flair/stroke-hex-opacity.svg'

const IndexPage = () => (
  <Layout isLanding>
    <Seo title="Contextual QA for dev teams" />
    <Box
      bgImage={fractalBg}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <NavBar />
      <Hero />
    </Box>
    <Box pos="relative" overflow="hidden" py={{ base: 10, md: 24}}>
      <Img
        pos="absolute"
        top="4%"
        left="22%"
        w="210px"
        height="auto"
        opacity="0.75"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        top="23%"
        right="15%"
        w="240px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        top="61.5%"
        left="36%"
        w="72px"
        height="auto"
        opacity="0.75"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        bottom="19.5%"
        left="59%"
        w="240px"
        height="auto"
        opacity="0.75"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        top={{ base: '50%', md: '43.5%' }}
        left={{ base: '-11%', md: '11.5%' }}
        w={{ base: '120px', md: '240px'}}
        height="auto"
        opacity="0.75"
        src={HexHoneycomb}
        alt="Hexagon Icon"
      />
      <SingleFeature
        inverted
        title="Your Dev Team's Secret Weapon"
        description={<>
          <Text fontSize="lg" mt="6">Your dev team is capable of more. Much more. CarbonQA rapidly provides a dedicated, US-based testing team that can unlock that potential. We help your devs build better software, faster and cheaper.</Text>
          <Text fontSize="lg" mt="6">Stop wasting your dev team's time on testing. A CarbonQA team immediately drives value by letting your devs get back to what they do best.</Text>
        </>}
        image={<Illustrations.Boxes />}
      />
      <SingleFeature
        title="Your Trusted Partner"
        description={<Text fontSize="lg" mt="6">
          CarbonQA is born and bred from a custom software company. We hire only US-based, full-time testers to work for you: this isn't a crowdsourced or mechanical turk-based solution. Instead, it's relationship-based QA, with context. Our team learns your product, your processes, and most importantly, your team. You get a trusted partner you can use as needed to ensure your product quality is maintained while freeing up your team to focus on other priorities.
        </Text>}
        image={<Illustrations.Handshake />}
      />
      <SingleFeature
        inverted
        action
        href="/how-it-works/"
        title="How Does It Work?"
        description={<Text fontSize="lg" mt="6">
          We charge a monthly subscription that ensures we have “project-ready” testers: resources that have been trained on your project and familiarized with your testing needs, there when you need them. We bill per tester, per day they spend testing your project. Your subscription includes your first few resource days per month, as well as our training and onboarding process for maintaining your project-ready team.
        </Text>}
        image={<Illustrations.Bolt />}
      />
      <SingleFeature
        title="Where Do We Fit?"
        description={<Text fontSize="lg" mt="6">
          CarbonQA is a perfect fit for companies with their own dev team, at nearly any phase of the software development process. We can help test your team’s work as they develop new features, against a user story or given acceptance criteria. We can help smoke test a major release before it’s pushed to production. We help build and curate test cases for your team. We work alongside your team so they can get back to delivering your product, with confidence.
        </Text>}
        image={<Illustrations.Fit />}
      />
      <SingleFeature
        inverted
        title="What Can We Test?"
        description={<Text fontSize="lg" mt="6">
          We focus primarily on manual testing of web, desktop, and mobile apps. We test against user stories, feature specs, and acceptance criteria, existing test plans, user experience, and, well, common sense. We'll build out a test plan for you if you don't have one, and help maintain it moving forward. We test responsive web, as well as virtual and physical devices with our considerable device lab. And our testers use Slack to communicate directly with your developers, tightening the feedback loop and helping pinpoint issues quickly.
        </Text>}
        image={<Illustrations.Devices />}
      />
    </Box>
    <TripleFeature />
    <CtaSimple
      title="Ready to unlock your team's full potential?"
      titleSize="xl"
      cta="Get Started"
      linkTo="/contact"
      showBg
    />
  </Layout>
)

export default IndexPage
