import {
  Box,
  Button,
  Center,
  Heading,
  Stack,
} from '@chakra-ui/react'
import * as React from 'react'
import { FaArrowRight } from 'react-icons/fa'

export const SingleFeature = ({ inverted, action, href, title, description, image }) => {
  return (
    <Box as="section" bg={action ? 'rgba(255, 255, 255, 0.025)' : 'transparent'} py={{ base: 20, md: 32}}>
      <Box
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: '6',
          md: '8',
        }}
      >
        <Stack
          spacing={{
            base: '4',
            lg: '20',
          }}
          direction={{
            base: 'column',
            lg: !inverted ? 'row' : 'row-reverse',
          }}
          justify="space-evenly"
          alignItems="center"
        >
          <Center flex="1" maxW={{ base: '360px', lg: '500px' }}>
            {image}
          </Center>
          <Box
            maxW={{
              lg: 'lg',
            }}
          >
            <Heading
              size="2xl"
              fontWeight="bold"
              letterSpacing="tight"
              lineHeight="normal"
            >
              {title}
            </Heading>
            {description}
            {action &&
              <Button
                as="a"
                href={href}
                className="group"
                mt="8"
                variant="base"
                size="lg"
                px="8"
                fontWeight="500"
                h="14"
                iconSpacing="3"
                rightIcon={
                  <Box
                    as={FaArrowRight}
                    fontSize="sm"
                    transition="transform 0.2s"
                    _groupHover={{
                      transform: 'translateX(2px)',
                    }}
                  />
                }
                _hover={{
                  backgroundColor: '#70081E',
                  textShadow: '1px 0 10px #fff',
                }}
              >
                Learn more
              </Button>
            }
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
