import { chakra, useToken } from '@chakra-ui/react'
import * as React from 'react'

export const Checkmark = (props) => {
  const { iconColor = 'currentColor', ...rest } = props
  const color = useToken('colors', iconColor)

  return (
    <chakra.svg fill="none" viewBox="0 0 44 36" {...rest}>
      <title>Checkmark</title>
      <defs>
        <filter id="filter-1" width="145%" height="157.6%" x="-20%" y="-27.3%" filterUnits="objectBoundingBox">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.648737981 0"/>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <filter id="filter-2" width="107.4%" height="109%" x="-3.7%" y="-4.5%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation=".60377359"/>
        </filter>
        <filter id="filter-3" width="103.9%" height="110.5%" x="-2%" y="-5.2%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation=".30188679"/>
        </filter>
      </defs>
      <g>
        <g filter="url(#filter-1)" transform="translate(2 2)">
          <path fill="#404040" fillOpacity=".32367242" fillRule="nonzero" d="M15.1993502 31.9764801l-9.96797074-9.9643247c-.47197503-.4711778-.47211489-1.2357544-.00062465-1.7074171.00010411-.0001042.00020823-.0002083.00062465 0l3.61196113-3.61064c.47166276-.4714902 1.23623931-.4713504 1.70772951.0003124a1.2126102 1.2126102 0 01.0009482.0009496l4.6472137 4.659258c.4709658.4721864 1.2355418.4731759 1.7077282.0022101a1.28388467 1.28388467 0 00.0013814-.0013801L33.7761114 4.47678196c.4713047-.47184821 1.2358812-.47209509 1.70761-.00067088.0000398.00003983.0000797.00007965 1e-7.00023902l3.6129107 3.61158916c.471975.47117785.4721148 1.23575442.0006246 1.70741717-.0001041.00010415-.0002082.00020828-.0006246 0L16.9073921 31.9764801c-.4718531.4710558-1.2361888.4710558-1.7080419 0z" filter="url(#filter-2)"/>
          <path fill="silver" fillRule="nonzero" d="M14.0048499 31.2111818c-.7098756-.3793995-1.7231365-1.1866004-3.0397829-2.4216028-2.17963629-2.044479-4.64389822-4.9770511-7.39278582-8.7977162l3.7e-7-3e-7c-.13332861-.1853128-.21139501-.4046242-.22514624-.6325019-.18438687-3.0555555.12307229-4.9819475.92237747-5.7791759 1.05546848-1.4648878 3.50164317.3234101 4.42154145.8107798.55428421.2936643 2.33807187 2.0568716 5.35136307 5.2896217l.0000253-.0000235c.4547137.4878306 1.2187969.5146772 1.7066275.0599635a1.20751503 1.20751503 0 00.1580954-.1798135C24.1892903 8.00632449 28.7150673 1.84416726 29.4844966 1.07424098c.7872176-.7877262 2.1457048-.4372336 4.0754615 1.05147777.0408573.03150133.0796445.06559941.1161317.10207327l4.4132855 4.41167119c.471975.47117785.4721148 1.23575441.0006246 1.70741717-.0001041.00010415-.0002082.00020828-.0006246 0L15.4277504 31.0002161c-.3761025.3759683-.9538897.4616328-1.4229005.2109657z"/>
          <path fill="#000" fillOpacity=".21003606" d="M36.0055393 5.69387987l2.6833193 1.48198668c.0729738.04030313.1648028.01381834.2051059-.05915543.0325991-.05902467.0220963-.13251143-.0257316-.18004139l-4.9633618-4.9324364L31.2215518.52224666c-.0729738-.04030313-.1648029-.01381834-.205106.05915543-.0325991.05902466-.0220963.13251143.0257317.18004139l4.9633618 4.93243639z" filter="url(#filter-3)"/>
          <path fill={color} fillRule="nonzero" d="M10.8219917 28.6557254L.85402097 18.6914007c-.47197503-.4711778-.47211489-1.2357544-.00062465-1.7074171.0001041-.0001042.00020823-.0002083.00062465 0l3.61196113-3.61064c.47166275-.4714902 1.23623932-.4713504 1.70772955.0003124a1.13190991 1.13190991 0 01.00094817.0009496l4.64721378 4.659258c.4709658.4721864 1.2355417.4731759 1.7077281.0022101a1.19833 1.19833 0 00.0013814-.0013801L29.398753 1.15602725c.4713046-.47184822 1.2358812-.4720951 1.7076099-.00067089.0000399.00003983.0000797.00007966 1e-7.00023902l3.6129107 3.61158916c.471975.47117785.4721148 1.23575442.0006246 1.70741718-.0001041.00010414-.0002082.00020827-.0006246 0L12.5300336 28.6557254c-.4718531.4710557-1.2361888.4710557-1.7080419 0z"/>
        </g>
      </g>
    </chakra.svg>
  )
}
