import { chakra } from '@chakra-ui/react'
import * as React from 'react'

export const Boxes = (props) => (
  <chakra.svg h={{ base: 400, md: 575}} viewBox="0 0 335 386" fill="none" {...props}>
    <title>Stacked boxes representing "Development", "Design", and "QA".</title>
    <path opacity="0.08" fillRule="evenodd" clipRule="evenodd" d="M156.367 6.40119C163.26 2.43796 171.74 2.43797 178.633 6.4012L323.799 89.872C330.728 93.8563 335 101.24 335 109.233V276.017C335 284.01 330.728 291.394 323.799 295.378L178.633 378.849C171.74 382.812 163.26 382.812 156.367 378.849L11.2008 295.378C4.27162 291.394 0 284.01 0 276.017L0 109.233C0 101.24 4.27162 93.8563 11.2008 89.872L156.367 6.40119Z" fill="url(#paint0_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M37.9316 236.716C36.0368 235.769 34.8398 233.832 34.8398 231.713V158.227C34.8398 156.108 36.0368 154.172 37.9316 153.224L112.739 115.821C114.313 115.033 116.167 115.033 117.741 115.821L192.548 153.224C194.443 154.172 195.64 156.108 195.64 158.227V231.713C195.64 233.832 194.443 235.769 192.548 236.716L117.741 274.119C116.167 274.907 114.313 274.907 112.739 274.119L37.9316 236.716Z" fill="white"/>
    <mask id="mask0" maskUnits="userSpaceOnUse" x="34" y="115" width="162" height="160">
    <path fillRule="evenodd" clipRule="evenodd" d="M37.9316 236.716C36.0368 235.769 34.8398 233.832 34.8398 231.713V158.227C34.8398 156.108 36.0368 154.172 37.9316 153.224L112.739 115.821C114.313 115.033 116.167 115.033 117.741 115.821L192.548 153.224C194.443 154.172 195.64 156.108 195.64 158.227V231.713C195.64 233.832 194.443 235.769 192.548 236.716L117.741 274.119C116.167 274.907 114.313 274.907 112.739 274.119L37.9316 236.716Z" fill="white"/>
    </mask>
    <g mask="url(#mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M115.24 275.37L34.8398 235.17V154.77L115.24 194.97V275.37Z" fill="#EBEBEB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M115.24 275.37L195.64 235.17V154.77L115.24 194.97V275.37Z" fill="#C8C8C8"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.8398 154.77L115.24 114.57L195.64 154.77L115.24 194.97L34.8398 154.77Z" fill="white"/>
    </g>
    <path d="M57.0936 231.057L61.2192 228.492L57.0936 221.857L58.3492 221.077L63.7304 229.73L58.3492 233.077L57.0936 231.057ZM52.4298 228.757L48.3042 222.123L52.4298 219.557L51.1742 217.538L45.793 220.885L51.1742 229.538L52.4298 228.757Z" fill="#333333" stroke="#333333"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M152.992 190.076L166.975 183.085V169.102L152.992 176.094V190.076Z" fill="black" fillOpacity="0.298022"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M128.381 281.606C126.486 280.659 125.289 278.722 125.289 276.604V203.117C125.289 200.998 126.486 199.062 128.381 198.114L203.188 160.711C204.762 159.924 206.616 159.924 208.19 160.711L282.997 198.114C284.892 199.062 286.089 200.998 286.089 203.117V276.604C286.089 278.722 284.892 280.659 282.997 281.606L208.19 319.01C206.616 319.797 204.762 319.797 203.188 319.01L128.381 281.606Z" fill="white"/>
    <mask id="mask1" maskUnits="userSpaceOnUse" x="125" y="160" width="162" height="160">
    <path fillRule="evenodd" clipRule="evenodd" d="M128.381 281.606C126.486 280.659 125.289 278.722 125.289 276.604V203.117C125.289 200.998 126.486 199.062 128.381 198.114L203.188 160.711C204.762 159.924 206.616 159.924 208.19 160.711L282.997 198.114C284.892 199.062 286.089 200.998 286.089 203.117V276.604C286.089 278.722 284.892 280.659 282.997 281.606L208.19 319.01C206.616 319.797 204.762 319.797 203.188 319.01L128.381 281.606Z" fill="white"/>
    </mask>
    <g mask="url(#mask1)">
      <path fillRule="evenodd" clipRule="evenodd" d="M205.689 320.26L125.289 280.06V199.66L205.689 239.86V320.26Z" fill="#EBEBEB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M205.689 320.26L286.089 280.06V199.66L205.689 239.86V320.26Z" fill="#C8C8C8"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M125.289 199.66L205.689 159.46L286.089 199.66L205.689 239.86L125.289 199.66Z" fill="#DCDCDC"/>
    </g>
    <path d="M153.573 265.79L152.372 263.857C152.04 263.303 151.457 263.016 151.107 263.234L143.071 268.231L145.537 272.197L153.573 267.2C153.923 266.982 153.923 266.332 153.573 265.79ZM141.277 269.096C140.564 268.744 139.879 268.723 139.375 269.036C138.87 269.35 138.587 269.974 138.587 270.769C138.587 272.079 137.546 272.256 136.793 271.885C137.618 273.511 139.035 274.99 140.38 275.654C141.332 276.123 142.244 276.152 142.917 275.733C143.59 275.315 143.968 274.484 143.968 273.423C143.968 272.627 143.684 271.724 143.18 270.913C142.675 270.101 141.991 269.448 141.277 269.096Z" fill="#333333"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M165.401 179.56L163.479 180.375L243.645 220.841L245.801 219.76L165.401 179.56Z" fill="black" fillOpacity="0.298022"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M74.9519 134.67L73.0293 135.485L153.196 175.951L155.352 174.87L74.9519 134.67Z" fill="black" fillOpacity="0.298022"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M96.2207 185.126C94.3258 184.178 93.1289 182.242 93.1289 180.123V106.637C93.1289 104.518 94.3258 102.582 96.2207 101.634L171.028 64.2306C172.602 63.4433 174.456 63.4433 176.03 64.2306L250.837 101.634C252.732 102.582 253.929 104.518 253.929 106.637V180.123C253.929 182.242 252.732 184.178 250.837 185.126L176.03 222.529C174.456 223.317 172.602 223.317 171.028 222.529L96.2207 185.126Z" fill="white"/>
    <mask id="mask2" maskUnits="userSpaceOnUse" x="93" y="63" width="161" height="161">
    <path fillRule="evenodd" clipRule="evenodd" d="M96.2207 185.126C94.3258 184.178 93.1289 182.242 93.1289 180.123V106.637C93.1289 104.518 94.3258 102.582 96.2207 101.634L171.028 64.2306C172.602 63.4433 174.456 63.4433 176.03 64.2306L250.837 101.634C252.732 102.582 253.929 104.518 253.929 106.637V180.123C253.929 182.242 252.732 184.178 250.837 185.126L176.03 222.529C174.456 223.317 172.602 223.317 171.028 222.529L96.2207 185.126Z" fill="white"/>
    </mask>
    <g mask="url(#mask2)">
      <path fillRule="evenodd" clipRule="evenodd" d="M173.529 223.78L93.1289 183.58V103.18L173.529 143.38V223.78Z" fill="#EBEBEB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M173.529 143.38L93.1289 103.18V22.78L173.529 62.98V143.38Z" fill="#9F9F9F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M173.529 223.78L253.929 183.58V103.18L173.529 143.38V223.78Z" fill="#C8C8C8"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M173.529 143.38L253.929 103.18V22.78L173.529 62.98V143.38Z" fill="#C5C5C5"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M173.529 143.38L253.041 103.615L279.64 135.38C280.196 136.043 279.986 137.051 279.212 137.438L203.236 175.427C201.541 176.274 199.483 175.822 198.299 174.342L173.529 143.38Z" fill="black" fillOpacity="0.15"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M173.529 143.38L94.0144 103.624L67.41 135.616C66.8578 136.28 67.0686 137.285 67.841 137.671L143.812 175.657C145.512 176.507 147.576 176.05 148.758 174.562L173.529 143.38Z" fill="black" fillOpacity="0.15"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M173.529 143.38L253.041 103.615L278.909 121.987C279.734 122.573 279.637 123.826 278.733 124.278L202.551 162.369C201.19 163.05 199.56 162.903 198.342 161.989L173.529 143.38Z" fill="#D3D3D3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M173.529 63.4152L253.041 103.18L277.071 97.7431C278.298 97.4652 278.5 95.8006 277.374 95.2376L201.659 57.3802C200.801 56.9511 199.818 56.8431 198.887 57.0759L173.529 63.4152Z" fill="#E0E0E0"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M173.529 143.38L94.0144 103.624L68.1311 122.22C67.3123 122.809 67.412 124.056 68.3137 124.507L144.493 162.597C145.862 163.282 147.501 163.129 148.721 162.204L173.529 143.38Z" fill="#E0E0E0"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M173.529 63.65L94.0162 103.619L63.5402 97.8858C62.2787 97.6485 62.0406 95.9444 63.1887 95.3703L138.796 57.5669C139.595 57.1671 140.505 57.0452 141.382 57.2205L173.529 63.65Z" fill="#D3D3D3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M112.975 178.098C114.706 176.461 114.549 172.823 112.506 169.584C110.293 166.076 106.705 164.365 104.492 165.761C102.279 167.158 102.279 171.133 104.492 174.64C106.535 177.879 109.752 179.587 111.975 178.729C111.984 178.746 111.994 178.762 112.005 178.779L115.01 183.542C115.287 183.98 115.735 184.194 116.012 184.019C116.288 183.845 116.288 183.348 116.012 182.91L113.007 178.147C112.996 178.13 112.985 178.114 112.975 178.098ZM111.504 170.216C113.164 172.846 113.164 175.828 111.504 176.875C109.844 177.922 107.153 176.639 105.493 174.008C103.834 171.378 103.834 168.396 105.493 167.349C107.153 166.302 109.844 167.585 111.504 170.216Z" fill="#333333" stroke="#333333"/>
    <defs>
    <linearGradient id="paint0_linear" x1="0" y1="0" x2="0" y2="385.25" gradientUnits="userSpaceOnUse">
    <stop/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    </defs>
  </chakra.svg>
)
export const Handshake = (props) => (
  <chakra.svg h={{ base: 400, md: 575}} viewBox="0 0 500 575" {...props}>
    <title>Handshake.</title>
    <defs>
      <linearGradient id="lineargradient" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%"/>
        <stop offset="100%" stopColor="#FFF"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="url(#lineargradient)" d="M266.615704 9.55402988L483.282371 134.137363C493.624449 140.084058 500 151.104371 500 163.03424v248.93152c0 11.929869-6.375551 22.950182-16.717629 28.896877L266.615704 565.44597c-10.287435 5.915276-22.943973 5.915276-33.231408 0L16.7176292 440.862637C6.3755509 434.915942 0 423.895629 0 411.96576V163.03424c0-11.929869 6.3755509-22.950182 16.7176292-28.896877L233.384296 9.55402988c10.287435-5.91527542 22.943973-5.91527542 33.231408 0z" opacity=".08"/>
      <g fillRule="nonzero">
        <path fill="#D2D2D2" d="M383.102266 318.490742l-10.668985-86.6684764-19.992656 2.4609375.008203-.0139453-38.759766-21.8129297c-4.134374-2.3264062-8.879062-3.3386719-13.603242-2.9022656l-61.200234 5.6601562c-7.523086.695625-14.245547 4.9883204-18.041133 11.5212891l-43.135312 74.2448442c-3.185274 5.482148-.684961 12.513046 5.246718 14.75332l1.764493.666094c3.394453 1.282148 6.883242 2.024531 10.37039 2.314921l-5.465742 4.760274-10.934766 9.527109-31.100507 26.528907c-5.53629 4.822617-6.111329 13.214414-1.288711 18.750703l3.154921 3.613476c6.19336 7.102266 16.973086 7.839727 24.088477 1.644727l11.886328 13.633594c3.864492 4.43789 9.526289 6.400898 14.965781 5.731523 3.268946-.401953 6.458321-1.757109 9.121875-4.086797l11.873204 13.634414c3.878437 4.449375 9.539414 6.399258 14.978906 5.729883 3.268945-.402773 6.45914-1.744805 9.123515-4.073672l6.452579 5.576484c6.195 7.114571 16.987031 7.850391 24.102421 1.655391l8.771602-7.652695 73.742813-57.431719.07957-.073008 29.094844-.028711-4.635586-37.663828z"/>
        <path fill="#000" fillOpacity=".1544198" d="M351.498086 333.855195l-.07957.073008-73.742813 57.431719-8.771601 7.652695c-7.114571 6.195-17.907422 5.45918-24.102422-1.65539l-6.452578-5.576485c-2.664375 2.328867-5.855391 3.671719-9.123516 4.073672-5.439492.669375-11.099649-1.280508-14.978906-5.729883l-11.873203-13.634414c-2.664375 2.328867-5.85375 3.684024-9.121875 4.086797-5.439493.669375-11.10129-1.292812-14.965782-5.731523l-11.886328-13.633594c-6.09164 5.304141-14.862422 5.510859-21.148476 1.006523-3.223829 4.861172-2.96625 11.449922 1.052461 16.06336l3.154921 3.613476c6.19336 7.102266 16.973086 7.839727 24.088477 1.644727l11.886328 13.633594c3.864492 4.43789 9.526289 6.400898 14.965781 5.731523 3.268946-.401953 6.4575-1.757109 9.121875-4.086797l11.873203 13.635235c3.878438 4.449374 9.539415 6.399257 14.978907 5.729882 3.268945-.402773 6.45914-1.744804 9.123515-4.073672l6.452578 5.576485c6.195 7.11457 16.987032 7.85039 24.102422 1.65539l8.771602-7.652695 73.742812-57.431719.079571-.073007 29.094843-.028711-3.218906-26.148282-33.02332 3.848086z"/>
        <path fill="#FFF" d="M364.926602 371.343477l-39.510352-34.067579-10.987266-9.466406-7.861875-6.770859-33.802617-29.1375002 3.903867-33.2308594c1.320703-11.2391015-5.754492-21.76125-16.661367-24.7791796l-73.68457-20.3888672c-7.79625-2.1574219-16.036289 1.4601562-19.724414 8.660039l-9.26625 18.0878907-39.7753127-2.6996485-10.1882812 86.7267192-4.4280469 37.689257 38.7171098 2.574961.08039.073008 80.884453 69.72082 8.814258 7.604297c7.149024 6.155625 17.936953 5.359922 24.092578-1.789101L267.325 426.462734c2.67668 2.314102 5.875078 3.638907 9.146484 4.023633 5.442774.639844 11.093086-1.342031 14.946914-5.812734l11.797735-13.700039c2.676679 2.314101 5.873437 3.652031 9.144844 4.035937 5.442773.639844 11.093906-1.354336 14.933789-5.814375l11.810039-13.699218c7.149023 6.155625 17.924648 5.358281 24.078632-1.778438l3.135235-3.631523c4.792265-5.561719 4.170469-13.950235-1.39207-18.7425z"/>
        <g fill="#CECECE">
          <path d="M364.926602 371.3434771l-12.034805-10.37695316c3.91125 5.18683594 3.830859 12.56554686-.583242 17.68921876l-3.328008 3.8546484c-6.532969 7.575586-17.971406 8.4221485-25.5609376 1.8875391l-12.5376563 14.5425c-4.0761328 4.7340234-10.0750781 6.8504297-15.8533594 6.1720312-3.4723828-.4076953-6.8660156-1.8276562-9.7075781-4.2844921l-12.5245312 14.5441406c-4.0908985 4.7455078-10.0882032 6.848789-15.8664844 6.1703906-3.4723828-.4076953-6.8676563-1.8145312-9.7092188-4.2713672l-12.52289058 14.5310156c-2.35921875 2.7406641-5.36402343 4.5871875-8.59195312 5.53875l5.33121094 4.5994922c7.14902346 6.155625 17.93695316 5.3599219 24.09257816-1.7891015l11.7960937-13.6877344c2.6766797 2.3141016 5.8750781 3.6389062 9.1464844 4.0236328 5.4427734.6390234 11.0930859-1.3420312 14.946914-5.8127344l11.7977344-13.700039c2.6766797 2.3141015 5.8734375 3.6520312 9.1448438 4.0359375 5.4427734.6398437 11.0939062-1.354336 14.9337891-5.814375l11.810039-13.6992188c7.149023 6.155625 17.924648 5.3582813 24.078633-1.7784375l3.135234-3.6315234c4.791446-5.5625391 4.169649-13.9510547-1.39289-18.7433203z"/>
          <path d="M343.105469 390.9350005l-21.6496877-18.6670313c-2.5716797-2.21812495-6.4575001-1.9310156-8.6772657.6423047-2.2189453 2.5733203-1.9310156 6.4583203.6423047 8.6772657l21.6111327 18.6342187 4.073672-4.725c1.625859 1.4002734 3.444492 2.4215625 5.348438 3.1098047 1.405195-2.5011328.907265-5.7266016-1.348594-7.6715625zM308.4907422 414.0768364c1.4823047-2.5142578 1.0073437-5.8028906-1.2813281-7.7765625l-19.4479688-16.7680078c-2.5733203-2.218125-6.4575-1.9310156-8.6772656.6423047-2.2189453 2.5733203-1.9310156 6.4583203.6423047 8.6772656l19.4479687 16.7680078c.0082032.0065625.0164063.0123047.0246094.0188672l4.0178906-4.6659375c1.6020703 1.3855078 3.3936328 2.4133594 5.2737891 3.1040625z"/>
        </g>
        <path fill="#B2B2B2" d="M146.1361328 363.63418l-4.6864453 6.188438c-5.10316406 6.738867-3.77753906 16.338984 2.96132813 21.442968 6.73886717 5.103164 16.33898437 3.777539 21.44296877-2.961328l4.6864453-6.188437c5.1031641-6.738867 3.7775391-16.338985-2.9613281-21.442969-6.7388672-5.103164-16.3398047-3.777539-21.4429688 2.961328z"/>
        <path fill="#B2B2B2" d="M174.6797266 376.650079l-8.8257422 11.654179c-5.1031641 6.738868-3.7775391 16.338985 2.9613281 21.442969 6.7388672 5.103164 16.3389844 3.777539 21.4429687-2.961328l8.8257422-11.65418c5.1031641-6.738867 3.7775391-16.338984-2.9613281-21.442968-6.7396875-5.103165-16.3398047-3.77754-21.4429687 2.961328z"/>
        <path fill="#B2B2B2" d="M202.2857031 390.903008l-12.0282422 15.882891c-5.103164 6.738867-3.777539 16.338984 2.9613282 21.442969 6.7388672 5.103164 16.3389843 3.777539 21.4429687-2.961328L226.69 409.384649c5.1031641-6.738867 3.7775391-16.338984-2.9613281-21.442969-6.7388672-5.103984-16.3389844-3.777539-21.4429688 2.961328z"/>
        <path fill="#B2B2B2" d="M227.3880859 408.462618l-12.7263281 16.804101c-5.1031641 6.738867-3.7775391 16.338985 2.9613281 21.442969 6.7388672 5.103164 16.3389844 3.777539 21.4429691-2.961328l12.726328-16.804102c5.103164-6.738867 3.777539-16.338984-2.961328-21.442968-6.739688-5.103164-16.339805-3.777539-21.4429691 2.961328z"/>
        <path fill="#D2D2D2" d="M143.67519531 364.454493L138.98875 370.64293c-5.10316406 6.738867-3.77753906 16.338985 2.96132813 21.442969 6.73886717 5.103164 16.33898437 3.777539 21.44296877-2.961328l4.6864453-6.188438c5.1031641-6.738867 3.7775391-16.338984-2.9613281-21.442968-6.7388672-5.103165-16.3398047-3.77754-21.44296879 2.961328zM172.2187891 377.470391l-8.8257422 11.65418c-5.1031641 6.738867-3.7775391 16.338984 2.9613281 21.442969 6.7388672 5.103164 16.3389844 3.777539 21.4429688-2.961329l8.8257421-11.654179c5.1031641-6.738867 3.7775391-16.338985-2.9613281-21.442969-6.7396875-5.103164-16.3398047-3.777539-21.4429687 2.961328z"/>
        <path fill="#D2D2D2" d="M199.8247656 391.723321l-12.0282422 15.88289c-5.103164 6.738868-3.777539 16.338985 2.9613282 21.442969 6.7388671 5.103164 16.3389843 3.777539 21.4429687-2.961328l12.0282422-15.882891c5.1031641-6.738867 3.7775391-16.338984-2.9613281-21.442968-6.7388672-5.103985-16.3389844-3.777539-21.4429688 2.961328z"/>
        <path fill="#D2D2D2" d="M224.9271484 409.28293l-12.7263281 16.804102c-5.1031641 6.738867-3.777539 16.338984 2.9613281 21.442969 6.7388672 5.103164 16.3389844 3.777539 21.4429686-2.961329l12.726328-16.804101c5.103164-6.738867 3.777539-16.338985-2.961328-21.442969-6.739687-5.103164-16.3398045-3.777539-21.4429686 2.961328z"/>
        <path fill="#A4A4A4" d="M316.970313 215.73757862c-4.134375-2.32640625-8.879063-3.33867187-13.603243-2.90226562l-61.200234 5.66015625c-7.523086.695625-14.2455469 4.98832035-18.0411329 11.52128905l-43.1353125 74.2448438c-3.1852734 5.4829689-.6849609 12.5130469 5.2467188 14.7533199l1.7644922.666094c15.8115234 5.971875 33.6680859.890039 43.9654687-12.5114061l16.5268357-21.5085938s16.44724 19.0432613 39.827787 18.3142551c8.637719-.2693244 18.187259-29.6818886 28.64862-88.23769258z"/>
        <path fill="#D2D2D2" d="M352.448828 234.2692583l-38.759765-21.81292968c-4.134375-2.32640624-8.879063-3.33867187-13.603243-2.90226562l-61.200234 5.66015625c-7.523086.695625-14.2455469 4.98832035-18.0411329 11.52128905l-43.1353125 74.2448438c-3.1852734 5.4829687-.6849609 12.5130469 5.2467188 14.7533199l1.7644922.666094c15.8115234 5.971875 33.6680859.890039 43.9654687-12.5114061l16.5268357-21.5085938s39.726914 45.9973829 83.465977-7.7191406"/>
        <path fill="#BABABA" d="M459.954883 367.743945L439.6825 203.06539062c-.199336-1.61929687-1.033594-3.09339843-2.319844-4.09746093-1.28625-1.0040625-2.917851-1.45523438-4.537969-1.25671875l-47.404218 5.83570312c-12.312071 1.5159375-21.095157 12.76570314-19.579219 25.07695314l16.278281 132.2343748c1.401094 11.383477 11.122617 19.749844 22.3125 19.749844.912188 0 1.83668-.055781 2.764453-.169805l47.404219-5.835703c3.371484-.415898 5.769258-3.486328 5.35418-6.858633z"/>
        <path fill="#000" fillOpacity=".1971427" d="M459.954883 367.743945l-2.225508-18.076406c-1.008984 1.480664-2.620078 2.538867-4.537969 2.775117l-49.69207 6.117071c-.972891.119765-1.94168.178007-2.898164.178007-8.367188 0-15.947695-4.46414-20.141133-11.353125l1.658672 13.472813c1.401094 11.383476 11.122617 19.749844 22.3125 19.749844.912187 0 1.83668-.055782 2.764453-.169805l47.404219-5.835703c3.372305-.415078 5.770078-3.485508 5.355-6.857813z"/>
        <path fill="#888" d="M114.5811719 203.54609375l-47.4042188-5.83570313c-1.6217578-.19933593-3.2517187.25265626-4.5379687 1.25671875-1.28625 1.00406251-2.1205078 2.47816407-2.3198438 4.09746094L40.04675781 367.743945c-.41507812 3.372305 1.98269532 6.442735 5.35417969 6.857813l47.4042187 5.835703c.9285938.114023 1.8514454.169805 2.7644532.169805 11.1890625 0 20.9114062-8.367188 22.3125-19.749844l16.2782812-132.2343751c1.5159375-12.31125-7.2671484-23.56101565-19.5792187-25.07695315z"/>
        <path fill="#000" fillOpacity=".19558566" d="M40.04675781 367.743945l2.22550782-18.076406c1.00898437 1.480664 2.62007812 2.538867 4.53796874 2.775117l49.69207033 6.117071c.9728906.119765 1.9416797.178007 2.898164.178007 8.3671876 0 15.9476954-4.46414 20.1411329-11.353125l-1.6586719 13.472813c-1.4010938 11.383476-11.1226172 19.749844-22.3125 19.749844-.9121875 0-1.8366797-.055782-2.7644531-.169805l-47.40421879-5.835703c-3.373125-.415078-5.77007812-3.485508-5.355-6.857813z"/>
        <g fill="#EBEEF2" transform="translate(66.25 329.070312)">
          <circle cx="354.375" cy="12.3055078" r="11.8928906"/>
          <circle cx="12.4605469" cy="12.3055078" r="11.8928906"/>
        </g>
      </g>
    </g>
  </chakra.svg>
)
export const Bolt = (props) => (
  <chakra.svg xmlnsXlink="http://www.w3.org/1999/xlink" h={{ base: 400, md: 575}} viewBox="0 0 500 575" {...props}>
    <title>Thunderbolt.</title>
    <defs>
      <filter id="b" width="475%" height="475%" x="-187.5%" y="-187.5%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="29.0909091"/>
      </filter>
      <filter id="c" width="134.1%" height="131.9%" x="-17%" y="-16%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="11.5"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.550207605 0"/>
      </filter>
      <linearGradient id="bg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%"/>
        <stop offset="100%" stopColor="#FFF"/>
      </linearGradient>
      <path id="d" d="M147.398073 0c4.325544 0 8.473926 1.7176167 11.532548 4.77499404l96.537796 96.49851796c6.36925 6.366659 6.36925 16.689052 0 23.055711-3.058622 3.057377-7.207003 4.774994-11.532548 4.774994l-57.085151-.001953.001953 70.647634c0 9.003815-7.302005 16.302849-16.309485 16.302849-4.325545 0-8.473926-1.717616-11.532548-4.774994l-96.5377963-96.498518c-6.3692501-6.366659-6.3692501-16.6890521 0-23.0557108 3.0586218-3.0573773 7.2070032-4.774994 11.5325476-4.774994l57.0804797-.0027172.002719-70.6429636C131.088588 7.2990343 138.390593 0 147.398073 0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="url(#bg)" d="M266.615704 9.55402988L483.282371 134.137363C493.624449 140.084058 500 151.104371 500 163.03424v248.93152c0 11.929869-6.375551 22.950182-16.717629 28.896877L266.615704 565.44597c-10.287435 5.915276-22.943973 5.915276-33.231408 0L16.7176292 440.862637C6.3755509 434.915942 0 423.895629 0 411.96576V163.03424c0-11.929869 6.3755509-22.950182 16.7176292-28.896877L233.384296 9.55402988c10.287435-5.91527542 22.943973-5.91527542 33.231408 0z" opacity=".08"/>
      <g transform="matrix(-1 0 0 1 409 153)">
        <path fill="#FFF" fillOpacity=".54832277" d="M169.006157 123.755941c-6.047022-3.491216-13.497159-3.491216-19.545408 0-26.173072 15.111417-98.6512807 56.956906-139.68816766 80.649176C3.72519129 207.896579 0 214.348945 0 221.331868c0 6.9828 3.72519129 13.435166 9.77258134 16.926628C50.9116023 262.010181 123.673135 304.019183 149.907585 319.165585c6.047022 3.491339 13.498386 3.491339 19.545408 0 26.174299-15.111662 98.651403-56.95666 139.689149-80.649422 6.047022-3.491338 9.772704-9.943827 9.772704-16.926628 0-6.9828-3.725682-13.435289-9.772704-16.926628-41.139634-23.751194-113.901535-65.761177-140.135985-80.906966z"/>
        <circle cx="157.090909" cy="238.545455" r="23.2727273" fill="#000" filter="url(#b)"/>
        <use fill="#000" filter="url(#c)" xlinkHref="#d"/>
        <use fill="#FFF" xlinkHref="#d"/>
      </g>
    </g>
  </chakra.svg>
)
export const Fit = (props) => (
  <chakra.svg xmlnsXlink="http://www.w3.org/1999/xlink" h={{ base: 400, md: 575}} viewBox="0 0 500 575" {...props}>
    <title>Solid and Outlined Boxes stacked one in front of the other.</title>
    <defs>
      <filter id="filter" width="127.7%" height="127.7%" x="-13.8%" y="-13.8%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feMorphology in="SourceAlpha" radius="15.1472888" result="shadowInner"/>
        <feOffset in="shadowInner" result="shadowInner"/>
        <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.596208479 0"/>
      </filter>
      <filter id="filter2" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic"/>
      </filter>
      <linearGradient id="bg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%"/>
        <stop offset="100%" stopColor="#FFF"/>
      </linearGradient>
      <rect id="rect" width="216.722747" height="216.722747" x="0" y="55.9284509" rx="18.642817"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="url(#bg)" d="M266.615704 9.55402988L483.282371 134.137363C493.624449 140.084058 500 151.104371 500 163.03424v248.93152c0 11.929869-6.375551 22.950182-16.717629 28.896877L266.615704 565.44597c-10.287435 5.915276-22.943973 5.915276-33.231408 0L16.7176292 440.862637C6.3755509 434.915942 0 423.895629 0 411.96576V163.03424c0-11.929869 6.3755509-22.950182 16.7176292-28.896877L233.384296 9.55402988c10.287435-5.91527542 22.943973-5.91527542 33.231408 0z" opacity=".08"/>
      <g transform="translate(90 160)">
        <use fill="#000" filter="url(#filter)" xlinkHref="#rect"/>
        <rect width="201.575458" height="201.575458" x="7.57364439" y="63.5020953" stroke="#FFF" strokeLinejoin="square" strokeWidth="15.1472888" rx="18.642817"/>
        <rect width="216.722747" height="216.722747" x="102.535493" fill="#FFF" fillOpacity=".54881447" filter="url(#filter2)" rx="18.642817"/>
      </g>
    </g>
  </chakra.svg>
)
export const Devices = (props) => (
  <chakra.svg xmlnsXlink="http://www.w3.org/1999/xlink" h={{ base: 400, md: 575}} viewBox="0 0 500 575" {...props}>
    <title>A Desktop and a Mobile device.</title>
    <defs>
      <filter id="filter-3" width="118.9%" height="125.4%" x="-9.4%" y="-12.7%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="9.5"/>
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.553704108 0"/>
      </filter>
      <filter id="filter-5" width="164.6%" height="487.4%" x="-32.3%" y="-193.7%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="9.5"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.553704108 0"/>
      </filter>
      <filter id="filter-7" width="164.6%" height="139.7%" x="-32.3%" y="-19.9%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="9.5"/>
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.553704108 0"/>
      </filter>
      <path id="path-2" d="M30 0h241.625972c16.568543 0 30 13.4314575 30 30v164.380296c0 16.568543-13.431457 30-30 30H30c-16.5685425 0-30-13.431457-30-30V30C0 13.4314575 13.4314575 0 30 0z"/>
      <path id="path-6" d="M16 0h56.2807723c8.836556 0 16 7.163444 16 16v111.456255c0 8.836556-7.163444 16-16 16H16c-8.836556 0-16-7.163444-16-16V16C0 7.163444 7.163444 0 16 0z"/>
      <linearGradient id="linearGradient-1" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%"/>
        <stop offset="100%" stopColor="#FFF"/>
      </linearGradient>
      <rect id="path-4" width="88.2807723" height="14.7134621" x="106.6726" y="235.415393" rx="7.35673103"/>
    </defs>
    <g id="Landing-Page" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g id="CarbonQA-v1-Copy-37" transform="translate(-807 -4265)">
        <g id="Group-42" transform="translate(137 4265)">
          <g id="Group-49" transform="translate(670)">
            <path id="Polygon-Copy-41" fill="url(#linearGradient-1)" d="M266.615704 9.55402988L483.282371 134.137363C493.624449 140.084058 500 151.104371 500 163.03424v248.93152c0 11.929869-6.375551 22.950182-16.717629 28.896877L266.615704 565.44597c-10.287435 5.915276-22.943973 5.915276-33.231408 0L16.7176292 440.862637C6.3755509 434.915942 0 423.895629 0 411.96576V163.03424c0-11.929869 6.3755509-22.950182 16.7176292-28.896877L233.384296 9.55402988c10.287435-5.91527542 22.943973-5.91527542 33.231408 0z" opacity=".08"/>
            <g id="Group-15" transform="translate(40 160)">
              <g id="Group-14" transform="translate(117.707696)">
                <g id="Rectangle">
                  <use fill="#000" filter="url(#filter-3)" xlinkHref="#path-2"/>
                  <path fill="#FFF" fillOpacity=".54673842" stroke="#FFF" strokeLinejoin="square" strokeWidth="18.3918276" d="M271.625972 9.19591378c5.74489 0 10.945911 2.32857662 14.710711 6.09337582 3.764799 3.7647991 6.093375 8.9658206 6.093375 14.7107104h0v164.380296c0 5.74489-2.328576 10.945912-6.093375 14.710711-3.7648 3.764799-8.965821 6.093376-14.710711 6.093376h0H30c-5.7448898 0-10.9459113-2.328577-14.7107104-6.093376-3.7647992-3.764799-6.09337582-8.965821-6.09337582-14.710711h0V30c0-5.7448898 2.32857662-10.9459113 6.09337582-14.7107104C19.0540887 11.5244904 24.2551102 9.19591378 30 9.19591378h0z"/>
                </g>
                <g id="Rectangle">
                  <use fill="#000" filter="url(#filter-5)" xlinkHref="#path-4"/>
                  <use fill="#FFF" xlinkHref="#path-4"/>
                </g>
              </g>
              <g id="Group-13" transform="translate(0 106.6726)">
                <g id="Rectangle">
                  <use fill="#000" filter="url(#filter-7)" xlinkHref="#path-6"/>
                  <path fill="#FFF" fillOpacity=".54673842" stroke="#FFF" strokeLinejoin="square" strokeWidth="11.0350965" d="M72.2807723 5.51754827c2.8946491 0 5.5152621 1.17328837 7.4122127 3.07023903C81.5899357 10.484738 82.7632241 13.1053509 82.7632241 16h0v111.456255c0 2.894649-1.1732884 5.515262-3.0702391 7.412213-1.8969506 1.89695-4.5175636 3.070239-7.4122127 3.070239h0H16c-2.8946491 0-5.515262-1.173289-7.4122127-3.070239-1.89695066-1.896951-3.07023903-4.517564-3.07023903-7.412213h0V16c0-2.8946491 1.17328837-5.515262 3.07023903-7.4122127C10.484738 6.69083664 13.1053509 5.51754827 16 5.51754827h0z"/>
                </g>
                <circle id="Oval" cx="44.1403862" cy="118.627288" r="8.27632241" fill="#FFF"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </chakra.svg>
)
