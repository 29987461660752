import {
  Box,
  SimpleGrid,
  Heading
} from '@chakra-ui/react'
import * as React from 'react'
import { Checkmark } from '../../assets/svg/Checkmark'
import { Feature } from './feature'
import smallHex from '../../assets/svg/smallHex.svg'

export const TripleFeature = (title, description) => {
  return (
    <Box
      as="section"
      bgImage={smallHex}
      bgPosition="center"
      bgRepeat="repeat"
      bgSize="64px"
      py="36"
      position="relative"
    >
      <Box
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: '6',
          md: '8',
        }}
        position="relative"
        zIndex="999"
      >
        <Heading as="h1" size="2xl" mb={16} textAlign="center">
          You're ready for CarbonQA if you:
        </Heading>
        <SimpleGrid
          columns={{
            base: 1,
            md: 3,
          }}
          spacing={{
            base: '12',
            md: '8',
          }}
        >
          <Feature title="Have your own dev team" icon={<Checkmark h={8} />}>
            If you have a small- or medium-sized internal dev team building a web or mobile app, but lack a formal QA team or process, we're probably a good fit.
          </Feature>
          <Feature title="Use Slack for communication" icon={<Checkmark h={8} />}>
            We require clients to use Slack to facilitate communication and visibility, but our team is comfortable reporting issues into the system of your choice. We’re at home filing issues in Github, Jira, and Gitlab, or even Google Sheets.
          </Feature>
          <Feature title="Are tired of bugs slipping through" icon={<Checkmark h={8} />}>
            Even if you have dedicated QA resources, we've helped teams by offering additional testers for smoke tests, an extra set of eyes, or access to more devices.
          </Feature>
        </SimpleGrid>
      </Box>
      <Box position="absolute" top="0" right="0" w="100%" h="100%" bgGradient="linear(to-b, brand.black, transparent, brand.black)" />
    </Box>
  )
}
