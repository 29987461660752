import { Box, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'

export const Feature = (props) => {
  const { title, children, icon } = props
  return (
    <Box>
      <Stack direction={{ base: 'row', md: 'column' }} mb="2">
        <Box color="white" fontSize="5xl">
          {icon}
        </Box>
        <Text as="h3" color="white" fontSize="xl" fontWeight="extrabold">
          {title}
        </Text>
      </Stack>
      <Text pr="6" lineHeight="tall">
        {children}
      </Text>
    </Box>
  )
}
export default Feature
