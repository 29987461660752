import {
  Box,
  Flex,
  Heading,
  Img,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import * as React from 'react'
import * as Logos from '../../assets/svg/Brands';
import heroIllustration from '../../assets/svg/hero-illustration.svg'

export const Hero = () => {
  return (
    <Box as="section" py={{ base: 12, md: 32 }} overflow="hidden">
      <Box
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: '6',
          md: '8',
        }}
      >
        <Flex
          align="center"
          direction={{
            base: 'column-reverse',
            lg: 'row',
          }}
          justify="space-between"
          mb="20"
        >
          <Box
            flex="1"
            maxW={{
              lg: 'lg',
            }}
          >
            <Heading as="h1" fontSize={{ base: "5xl", md: "7xl" }} fontWeight="light" lineHeight="1.25">
              Contextual <strong>QA</strong> for dev teams
            </Heading>
            <Text mt="6" fontSize={{ base: 'lg', md: 'xl' }}>
              Let devs be devs. Leave the testing to us. No need to build and maintain complex test cases yourself. No need to train your team on a new piece of software. And no need to pull precious dev cycles to test your latest build.
            </Text>
          </Box>
          <Box
            boxSize={{
              base: '20',
              lg: '8',
            }}
          />
          <Img
            pos="relative"
            w="100%"
            maxW={560}
            height="auto"
            src={heroIllustration}
            alt="Illustration of a QA engineer testing a mobile application"
          />
        </Flex>
        <Box>
          <Text fontSize="lg" color="utility.alpha50" align="center" fontWeight="medium">
            We work alongside your team, where you already are:
          </Text>
          <SimpleGrid
            mt="14"
            columns={{
              base: 3,
              md: 6,
            }}
            color="utility.alpha50"
            alignItems="center"
            justifyItems="center"
            spacing={{
              base: '12',
              lg: '24',
            }}
            fontSize="2xl"
          >
            <Logos.Slack display={{ base: 'none', md: 'block' }} height="28px" />
            <Logos.SlackMobile display={{ base: 'block', md: 'none' }} height="28px" />
            <Logos.Github display={{ base: 'none', md: 'block' }} height="28px" />
            <Logos.GithubMobile display={{ base: 'block', md: 'none' }} height="28px" />
            <Logos.GitLab display={{ base: 'none', md: 'block' }} height="28px" />
            <Logos.GitLabMobile display={{ base: 'block', md: 'none' }} height="28px" />
            <Logos.Jira display={{ base: 'none', md: 'block' }} height="28px" />
            <Logos.JiraMobile display={{ base: 'block', md: 'none' }} height="28px" />
            <Logos.Asana display={{ base: 'none', md: 'block' }} height="28px" />
            <Logos.AsanaMobile display={{ base: 'block', md: 'none' }} height="28px" />
            <Logos.Trello display={{ base: 'none', md: 'block' }} height="28px" />
            <Logos.TrelloMobile display={{ base: 'block', md: 'none' }} height="28px" />
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}
